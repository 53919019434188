<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header
          class="md-card-header-icon"
          :class="getClass(headerColor)"
        >
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>

          <h4 class="title">
            Editar Perfil - <small>Complete seu perfil</small>
          </h4>
          <div class="md-size-100 text-left">
            <md-button
              @click="goToChangePassword"
              class="md-raised md-success mt-4"
            >
              alterar senha
            </md-button>
            <br />
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeCompleto"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                  min: 3,
                  max: 250,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    name="Nome Completo"
                    v-model="form.nomeCompleto"
                    type="text"
                    :maxlength="250"
                    class="uppercase"
                  ></md-input>
                  <!--<p id="error">{{ errors[0] }}</p>-->
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeusuario"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\'\.\-\_\@\0-9]*$/,
                  min: 8,
                  max: 50,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome de Usuário</label>
                  <md-input
                    v-model="form.nomeusuario"
                    type="text"
                    :maxlength="50"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email</label>
                  <md-input v-model="form.email" type="email"></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.cpfUsuario"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfUsuario"
                    type="text"
                    :maxlength="14"
                    disabled
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <!-- <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Entidade</label>
                <md-input v-model="form.nomeEntidade" disabled></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>papel</label>
                <md-input
                  v-model="form.nomePapel"
                  type="text"
                  disabled
                ></md-input>
              </md-field>
            </div>
          </div>-->
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>id de Usuario</label>
                <md-input
                  v-model="form.idUsuario"
                  type="text"
                  disabled
                ></md-input>
              </md-field>
            </div>
          </div>
          <span
            class="material-icons"
            style="color:red; transform:rotate(90deg);"
          >
            block
          </span>
          Campos com este Simbolo não são alterávies - Dúvidas tem em contato.
          <div class="md-layout-item md-size-100 text-left">
            <md-button type="submit" class="md-raised md-success mt-4">
              Atualizar
            </md-button>
          </div>
          <br />
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { store } from "../../../../store";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
var fields = {
  "form.cpfUsuario": {
    regex: "O campo CPF é inválido !"
  }
};
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
Vue.set(pt_br, "fields", fields);
localize("pt_BR", pt_br);

export default {
  name: "edit-profile-form",
  props: {
    headerColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      mensagem: "",
      papel: "",
      form: {
        nomeusuario: null,
        idEntidade: null,
        email: null,
        nomeCompleto: null,
        cpfUsuario: null,
        nomePapel: "",
        nomeEntidade: null,
        idUsuario: ""
      }
    };
  },
  beforeMount() {
    this.getUser();
  },
  mounted() {},
  methods: {
    ...mapActions("user", ["ActionGetUserById", "ActionPUTUsuarioPerfil"]),
    ...mapActions("auth", ["ActionSetToken", "ActionLoadSession"]),

    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    goToChangePassword() {
      window._Vue.$router //.push({ path: `usuarios/editar_usuario/${id}` })
        .push({
          name: "Change User Password",
          params: { id: this.form.idUsuario }
        })
        .catch(err => {});
    },
    async submit() {
      try {
        let papel = this.papel;
        let id = this.form.idUsuario;
        let form = this.form;
        await this.ActionPUTUsuarioPerfil({ id, papel, form }).then(
          response => {
            if (response.status === 200) {
              this.mensagem = response.body.mensagem;
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: this.mensagem,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
            this.ActionSetToken(response.body.jwt).then(() => {
              this.ActionLoadSession(response.body.jwt);
            });
            this.reload;
          }
        );
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    getUser() {
      this.form.idUsuario = this.user_profile_state.idUsuario;
      this.papel = this.user_profile_state.papel;
      if (this.user_profile_state.perfilAtual === null) {
        this.form.nomeusuario = this.user_profile_state.nomeusuario;
        this.form.email = this.user_profile_state.email;
        this.form.nomeCompleto = this.user_profile_state.nomeCompleto;
        this.form.cpfUsuario = this.user_profile_state.cpfUsuario;
        this.form.idPapel = this.user_profile_state.idPapel;
        this.form.nomePapel = this.user_profile_state.papel;
      } else {
        this.getUserById(this.form.idUsuario, this.papel).then(res => {
          //this.form.idEntidade = res.idEntidade;
          //this.form.nomeEntidade = this.userState.nomeEntidade;
          this.form.nomeusuario = res.body.nomeusuario;
          this.form.email = res.body.email;
          this.form.nomeCompleto = res.body.nomeCompleto;
          this.form.cpfUsuario = res.body.cpfUsuario;
          this.form.idPapel = res.body.idPapel;
          this.form.nomePapel = res.body.papel;
        });
      }
    },
    async getUserById(id, papel) {
      try {
        let idEntidade = this.user_profile_state.idEntidade;
        let response;
        return (response = await this.ActionGetUserById({
          id,
          papel,
          idEntidade
        }).then(response => {
          return response;
        }));
        ////console.log(response);
      } catch (err) {
        this.handleError(err);
      }
    }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapState("user", ["usuario"]),
    user_profile_state: state => state.user_profile,
    userState: state => state.usuario,
    reload: function() {
      return this.getUser();
    }
  },
  watch: {
    "form.cpfUsuario": function(val, oldVal) {
      if (val !== null && val !== "" && typeof val !== "undefined") {
        let cpf = cpfMask(val);
        this.form.cpfUsuario = cpf;
      } else {
        this.form.cpfUsuario = null;
      }
    }
  }
};
</script>
<style>
.uppercase {
  text-transform: uppercase;
}

#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
</style>
