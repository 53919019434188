<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <!-- img class="img" :src="cardUserImage" /> -->
      <i class="material-icons md-48">face</i>
    </div>

    <md-card-content>
      Nome de usuário:
      <h6 class="category text-gray">{{ getUser().nomeusuario }}</h6>
      <h4 class="card-title">
        Nome completo:
        {{ getUser().nomeCompleto }}
      </h4>
      <h4 class="card-title">
        CPF :
        {{ getUser().cpfUsuario }}
      </h4>
      <h4 class="card-title">
        Email:
        {{ getUser().email }}
      </h4>

      <div class="container4">
        <table
          class=""
          border="0"
          style="height: 42px; border-collapse: collapse;"
        >
          <thead>
            <tr style="height: 10px;">
              <td style="width: 60%;text-align: left; padding-right: 10px;">
                <h4 class="card-title">Entidades</h4>
              </td>
              <td style="width: 30%;text-align: left;">
                <h4 class="card-title">Papeis</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in user_profile_state.usuarioEntidade"
              :key="item.idUsuarioEntidade"
              style="height: 10px;"
            >
              <td style="width: 60%;text-align: left;  padding-right: 10px;">
                {{ item.nomeEntidade }}
              </td>
              <td style="width: 30%;text-align: left;">
                {{ item.papel }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <p class="card-description">id de Usuario: {{ getUser().idUsuario }}</p>
      <!--<p class="card-description">
        {{ getUser() }}
      </p>-->
      <md-button
        class="md-round"
        :class="getColorButton(buttonColor)"
        @click="goToEdit"
        >Edit</md-button
      >
    </md-card-content>
  </md-card>
</template>
<script>
import { store } from "../../../../store";
import { mapState } from "vuex";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";

export default {
  name: "user-card",
  props: {
    cardUserImage: {
      type: String,
      default: "./img/faces/marc.jpg"
    },
    buttonColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      userProfile: ""
    };
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile
  },
  methods: {
    getColorButton: function(buttonColor) {
      return "md-" + buttonColor + "";
    },
    getUser() {
      return (this.userProfile = this.user_profile_state);
    },
    goToEdit() {
      window._Vue.$router.push({ name: "Editar Perfil" }).catch(err => {});
    }
  },
  watch: {
    "userProfile.cpfUsuario": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.userProfile.cpfUsuario = cpf;
    }
  }
};
</script>
<style>
.material-icons.md-48 {
  font-size: 48px;
}

div.container4 {
  height: 10em;
  position: relative;
}
div.container4 table {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
